import React from 'react';
import { useAzureLoginUrl } from '@/hooks/apiHooks';
import Link from 'next/link';
import { LinkContent, WelComeContent } from '@/components/NewLogin/styles';
import { Skeleton } from '@mui/material';
import { Property } from 'csstype';
import FlexDirection = Property.FlexDirection;
import { useRouter } from 'next/router';
import { LoginRedirectPathSessionKey } from '@/constant/key';

type P = {
  title?: string;
  hasMsImage?: boolean;
  direction?: FlexDirection;
};
const MSLoginButton = ({ title = 'MS로 로그인' }: P) => {
  const router = useRouter();
  const { data } = useAzureLoginUrl();

  const onClickLogin = () => {
    if (!router.isReady || typeof router.query.redirect !== 'string') return;

    const encodedUrl = encodeURIComponent(router.query.redirect);
    sessionStorage.setItem(LoginRedirectPathSessionKey, encodedUrl);
  };

  return data ? (
    <Link href={data.msAzureLoginUrl} onClick={onClickLogin}>
      <WelComeContent>
        반갑습니다.
        <br />
        F&F 포탈에 오신걸 환영합니다.
      </WelComeContent>
      <LinkContent>{title}</LinkContent>
    </Link>
  ) : (
    <Skeleton style={{ width: '15vw', height: '10vh' }} />
  );
};

export default MSLoginButton;
